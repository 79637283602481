<template>
  <div>
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="30%"
      class="dialog"
      center
    >
      <div class="goodsWrap">
        <div class="totalWrap">共{{ total }}件</div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="d-flex a-center mb-10"
        >
          <div style="margin-right: 10px">{{ item.product_name }}</div>
          <div>{{ item.product_qty }}件</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ViewGoods",
  props: [""],
  data() {
    return {
      dialogFormVisible: false,
      platFormTitle: "查看商品",
      total: "0",
      list: [],
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.total = data.original_qty || 0;
      this.list = data.store_logistics_tonnage_order_detail || [];
      this.dialogFormVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsWrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.totalWrap {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
