<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showFullRefund"
      width="30%"
      class="dialog"
      center
    >
      <div class="boxWrap">
        <div>您确认给自采品退款，编号{{ order_no }}</div>

        <div class="mt-20 d-flex">
          <div>请输入退款原因</div>
          <div class="inputWrap">
            <el-input
              v-model="refund_remark"
              placeholder="请输入内容"
              type="textarea"
              maxlength="32"
            ></el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer mt-20">
          <el-button @click="showFullRefund = false">取 消</el-button>
          <el-button type="primary" @click="submitFrom" :loading="submitLoading"
            >确认退款</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { postTonnageOrderRefund } from "@/api/logisticsControl/online-self-procurement";
export default {
  name: "FullRefund",
  props: ["tableData"],
  data() {
    return {
      showFullRefund: false,
      title: "确认退款",
      refund_remark: "",
      submitLoading: false,
      order_no: "",
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.showFullRefund = true;
      this.order_no = data.order_no;
      this.refund_remark = "";
    },
    /**
     * 确认退款
     */
    async submitFrom() {
      if (this.refund_remark == "" || this.refund_remark.trim() == "") {
        this.$message({
          type: "error",
          message: "请输入退款原因",
        });
        return;
      }
      this.submitLoading = true;
      try {
        const res = await postTonnageOrderRefund({
          order_no: this.order_no,
          refund_remark: this.refund_remark,
        });
        console.log(res, "res==");
        this.$message({
          type: "success",
          message: "退款成功!",
        });
        this.$emit("onSubmitRefresh");
      } catch (error) {
        console.log(error, "postTonnageOrderRefund");
      } finally {
        this.submitLoading = false;
        this.showFullRefund = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.boxWrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputWrap {
  width: 200px;
  margin-left: 20px;
}
</style>
