<template>
  <div class="self-procurement-register">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-select v-model="formData.settle_state" clearable placeholder="请结算状态">
            <el-option label="已结算" value="1"></el-option>
            <el-option label="未结算" value="0"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="settle_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="结算时间始"
            end-placeholder="结算时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.product_name"
            placeholder="登记商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.member_name"
            placeholder="登记员姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_name"
            placeholder="收货人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_mobile"
            placeholder="收货人电话"
            clearable
          ></el-input>
        </div>
      
        <div class="inputs">
          <el-select
            filterable
            v-model="formData.logistics_id"
            clearable
            placeholder="选择城市仓"
            @change="onChangeCityStore"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            filterable
            v-model="formData.delivery_id"
            clearable
            :filter-method="(val) => onFilterMethod('selfTake', val)"
            @visible-change="(val) => onChangeVisible('selfTake', val)"
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.shipper_name"
            placeholder="发货人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.shipper_mobile"
            placeholder="发货人电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.order_no"
            placeholder="自采品编码"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.state" clearable placeholder="订单状态">
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.print_pdf"
            clearable
            placeholder="打单状态"
          >
            <el-option
              v-for="(item, index) in blackmailList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="登记时间始"
            end-placeholder="登记时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            @click="exportHandle"
            icon="el-icon-download"
            :disabled="!tableData.length || disabledExport"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <table-list
        v-loading="loading"
        :tableData="tableData"
        @openViewGoods="openViewGoods"
        @openFullRefund="openFullRefund"
      ></table-list>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 查看商品 start -->
    <view-goods ref="viewGoodsRef"></view-goods>
    <!-- 查看商品 start -->

    <!-- 全部退款 start -->
    <full-refund
      ref="fullRefundRef"
      @onSubmitRefresh="onSubmitRefresh"
    ></full-refund>
    <!-- 全部退款 start -->
  </div>
</template>
<script>
import TableList from "./modules/table-list/index.vue";
import ViewGoods from "./modules/view-goods/index.vue";
import FullRefund from "./modules/full-refund/index.vue";
import debounce from "lodash/debounce";
import { BLACKMAIL_STATUS_ENUM } from "./utils/enum/index.js";
import { ONLINE_SELF_PROCUREMENT_STATUS_ENUM } from "@/utils/enum/online-self-procure.js";
import { postTonnageOrderList } from "@/api/logisticsControl/online-self-procurement";
import { BASE } from "@/api";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { postTonnageOrderListExport } from "@/api/export/index.js";
export default {
  name: "OnlineSelfProcurement",
  components: { TableList, ViewGoods, FullRefund },
  data() {
    return {
      ONLINE_SELF_PROCUREMENT_STATUS_ENUM,
      stateList: Object.values(ONLINE_SELF_PROCUREMENT_STATUS_ENUM),
      BLACKMAIL_STATUS_ENUM,
      blackmailList: Object.values(BLACKMAIL_STATUS_ENUM),
      loading: false,
      pay_at: "",
      settle_at: "",
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      formData: {
        logistics_id: "", //		是	城市仓id
        delivery_id: "", //		是	自提点id
        receiver_name: "", //		是	收货人姓名
        receiver_mobile: "", //		是	收货人电话
        product_name: "", //		是	商品名称
        member_name: "",
        shipper_name: "", //		是	发货人名称
        shipper_mobile: "", //		是	发货人电话
        order_no: "",
        state: "",
        print_pdf: "",
        page: 1,
        pageSize: 10,
        create_time: "",
        settle_state:'',
        settle_time:''
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      disabledExport: false,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /** 查看商品 */
    openViewGoods(data) {
      this.$refs.viewGoodsRef.onInitData(data);
    },
    /** 全部退款 */
    openFullRefund(data) {
      this.$refs.fullRefundRef.onInitData(data);
    },
    /** 初始化 */
    onInitData() {
      this.getAjaxCityStoreList();
      this.hqlist();
    },
    /** 退款完成 刷新列表 */
    onSubmitRefresh() {
      this.formData.page = 1;
      this.hqlist();
    },
    /** 登记时间转换 */
    hqtime() {
      if (this.pay_at != null) {
        if (this.pay_at.length > 0) {
          this.formData.create_time = this.pay_at.join(",");
        } else {
          this.formData.create_time = "";
        }
      } else {
        this.formData.create_time = "";
      }
      if (this.settle_at != null) {
        if (this.settle_at.length > 0) {
          this.formData.settle_time = this.settle_at.join(",");
        } else {
          this.formData.settle_time = "";
        }
      } else {
        this.formData.settle_time = "";
      }
    },
    /** 列表 */
    async hqlist() {
      this.hqtime();
      this.loading = true;
      try {
        const res = await postTonnageOrderList(this.formData);
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    /** 导出 */
    async exportHandle() {
      this.hqtime();
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await postTonnageOrderListExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax postTonnageOrderListExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.pay_at = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    /*
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      this.selfTakeList = []; // 重置自提点
      this.formData.delivery_id = ""; // 重置自提点搜索
      const params = {
        name: "",
        logisticsId: data,
      };
      this.getAjaxSelfTakeList(params);
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.getAjaxCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.getAjaxCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.getAjaxSelfTakeList(params);
      }
    },
    /**
     * 获取城市仓列表
     */
    getAjaxCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.selfPurchaseLogisticsList(
          params
        );
        this.cityStoreList = data.data;
        console.log("ajx getAjaxCityStoreList", data.data);
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    }, 500),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.formData.logistics_id) {
        return;
      }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    500),
  },
};
</script>
<style lang="scss">
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}
.position-subset {
  position: absolute;
  right: -20%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
.self-procurement-register {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
