var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "tooltip-effect": "dark",
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          type: "index",
          width: "100px",
          align: "center",
          label: "序号",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "business.name", align: "center", label: "集配中心" },
      }),
      _c("el-table-column", {
        attrs: { prop: "order_no", align: "center", label: "自采品编码" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "store_logistics.name",
          align: "center",
          label: "城市仓",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "store_delivery.name",
          align: "center",
          label: "自提点",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "member.fullname", align: "center", label: "用户姓名" },
      }),
      _c("el-table-column", {
        attrs: { prop: "member.mobile", align: "center", label: "用户电话" },
      }),
      _c("el-table-column", {
        attrs: { prop: "receiver_name", align: "center", label: "收货人姓名" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "receiver_mobile",
          align: "center",
          label: "收货人电话",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "shipper_name", align: "center", label: "发货人姓名" },
      }),
      _c("el-table-column", {
        attrs: { prop: "shipper_mobile", align: "center", label: "发货人电话" },
      }),
      _c("el-table-column", {
        attrs: { prop: "create_time", align: "center", label: "登记时间" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "original_tonnage",
          align: "center",
          label: "登记重量（斤）",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "qc_tonnage",
          align: "center",
          label: "复核确认重量（斤）",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "original_qty", align: "center", label: "登记件数" },
      }),
      _c("el-table-column", {
        attrs: { prop: "qc_qty", align: "center", label: "复核确认件数" },
      }),
      _c("el-table-column", {
        attrs: { prop: "order_amt", align: "center", label: "支付金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tool.toDecimal2(
                        (scope.row.order_amt * 10000) / 100 / 10000
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "freight_amt", align: "center", label: "城市仓运费" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tool.toDecimal2(
                        (scope.row.freight_amt * 10000) / 100 / 10000
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "service_amt", align: "center", label: "服务费" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tool.toDecimal2(
                        (scope.row.service_amt * 10000) / 100 / 10000
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "order_amt", align: "center", label: "变动金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s("") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "state", align: "center", label: "订单状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      Object.values(
                        _vm.ONLINE_SELF_PROCUREMENT_STATUS_ENUM
                      ).find((item) => item.value == scope.row.state).label
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "settle_state", align: "center", label: "结算状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(scope.row.settle_state == 1 ? "已结算" : "未结算") +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "settle_time", align: "center", label: "结算时间" },
      }),
      _c("el-table-column", {
        attrs: { prop: "print_pdf", align: "center", label: "打单状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      Object.values(_vm.BLACKMAIL_STATUS_ENUM).find(
                        (item) => item.value == scope.row.print_pdf
                      ).label
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "操作",
          align: "center",
          fixed: "right",
          "min-width": "130",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.show_refund
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onFullRefund(scope.row)
                          },
                        },
                      },
                      [_vm._v("全部退款")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onViewGood(scope.row)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }