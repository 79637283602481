var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "goodsWrap" },
            [
              _c("div", { staticClass: "totalWrap" }, [
                _vm._v("共" + _vm._s(_vm.total) + "件"),
              ]),
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "d-flex a-center mb-10" },
                  [
                    _c("div", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(item.product_name)),
                    ]),
                    _c("div", [_vm._v(_vm._s(item.product_qty) + "件")]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }