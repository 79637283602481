/**
 * 线上自采 打单状态
 */
export const BLACKMAIL_STATUS_ENUM = {
  1: {
    value: 1,
    label: "已打单",
  },
  0: {
    value: 0,
    label: "未打单",
  },
};
