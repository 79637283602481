<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    tooltip-effect="dark"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" width="100px" align="center" label="序号">
    </el-table-column>
    <el-table-column prop="business.name" align="center" label="集配中心">
    </el-table-column>
    <el-table-column prop="order_no" align="center" label="自采品编码">
    </el-table-column>
    <el-table-column prop="store_logistics.name" align="center" label="城市仓">
    </el-table-column>
    <el-table-column prop="store_delivery.name" align="center" label="自提点">
    </el-table-column>
    <el-table-column prop="member.fullname" align="center" label="用户姓名">
    </el-table-column>
    <el-table-column prop="member.mobile" align="center" label="用户电话">
    </el-table-column>
    <el-table-column prop="receiver_name" align="center" label="收货人姓名">
    </el-table-column>
    <el-table-column prop="receiver_mobile" align="center" label="收货人电话">
    </el-table-column>
    <el-table-column prop="shipper_name" align="center" label="发货人姓名">
    </el-table-column>
    <el-table-column prop="shipper_mobile" align="center" label="发货人电话">
    </el-table-column>
    <el-table-column prop="create_time" align="center" label="登记时间">
    </el-table-column>
    <el-table-column
      prop="original_tonnage"
      align="center"
      label="登记重量（斤）"
    >
    </el-table-column>
    <el-table-column
      prop="qc_tonnage"
      align="center"
      label="复核确认重量（斤）"
    >
    </el-table-column>
    <el-table-column prop="original_qty" align="center" label="登记件数">
    </el-table-column>
    <el-table-column prop="qc_qty" align="center" label="复核确认件数">
    </el-table-column>
    <el-table-column prop="order_amt" align="center" label="支付金额">
      <template slot-scope="scope">
        {{ tool.toDecimal2((scope.row.order_amt * 10000) / 100 / 10000) }}
      </template>
    </el-table-column>
    <el-table-column prop="freight_amt" align="center" label="城市仓运费">
      <template slot-scope="scope">
        {{ tool.toDecimal2((scope.row.freight_amt * 10000) / 100 / 10000) }}
      </template>
    </el-table-column>
    <el-table-column prop="service_amt" align="center" label="服务费">
      <template slot-scope="scope">
        {{ tool.toDecimal2((scope.row.service_amt * 10000) / 100 / 10000) }}
      </template>
    </el-table-column>
    <el-table-column prop="order_amt" align="center" label="变动金额">
      <template slot-scope="scope">
        {{ "" }}
      </template>
    </el-table-column>
    <el-table-column prop="state" align="center" label="订单状态">
      <template slot-scope="scope">
        {{
          Object.values(ONLINE_SELF_PROCUREMENT_STATUS_ENUM).find(
            (item) => item.value == scope.row.state
          ).label
        }}
      </template>
    </el-table-column>
    <el-table-column prop="settle_state" align="center" label="结算状态">
      <template slot-scope="scope">
        {{
         scope.row.settle_state==1?'已结算':'未结算'
        }}
      </template>
    </el-table-column>
    <el-table-column prop="settle_time" align="center" label="结算时间">
    </el-table-column>
    <el-table-column prop="print_pdf" align="center" label="打单状态">
      <template slot-scope="scope">
        {{
          Object.values(BLACKMAIL_STATUS_ENUM).find(
            (item) => item.value == scope.row.print_pdf
          ).label
        }}
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" fixed="right" min-width="130">
      <template slot-scope="scope">
        <!-- 已复核状态 才能显示全部退款按钮 -->
        <el-button
          type="text"
          @click="onFullRefund(scope.row)"
          v-if="scope.row.show_refund"
          >全部退款</el-button
        >
        <el-button type="text" @click="onViewGood(scope.row)">查看</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { BLACKMAIL_STATUS_ENUM } from "../../utils/enum";
import { ONLINE_SELF_PROCUREMENT_STATUS_ENUM } from "@/utils/enum/online-self-procure.js";
export default {
  name: "TableList",
  props: ["tableData"],
  data() {
    return {
      BLACKMAIL_STATUS_ENUM,
      ONLINE_SELF_PROCUREMENT_STATUS_ENUM,
    };
  },
  methods: {
    /**
     * 全部退款
     */
    onFullRefund(row) {
      this.$emit("openFullRefund", row);
    },
    /**
     * 查看商品
     */
    onViewGood(row) {
      this.$emit("openViewGoods", row);
    },
  },
};
</script>
<style lang="scss" scoped></style>
